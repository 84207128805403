body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Open Sans", sans-serif !important;
}

input,
textarea {
  font-family: "Open Sans", sans-serif !important;
}

#AppTitle {
  margin-top: 50px;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600;
}

.ui.clearing.segment {
  border-radius: 0 !important;
}

.ui.negative.button,
.ui.negative.buttons .button {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
  background-color: #c00000 !important;
}

.ui.secondary.button,
.ui.secondary.buttons .button {
  font-family: "Open Sans", sans-serif !important;
  font-weight: bold;
  background-color: #ffffff !important;
  border: #c00000;
  border-radius: 1rm;
  color: #c00000;
}

.ui.basic.red.button,
.ui.basic.red.buttons .button {
  box-shadow: 0 0 0 2px #c00000 inset !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: bold;
  color: #c00000 !important;
}

.ui.secondary.segment {
  background: #f3f3f3;
  color: rgba(0, 0, 0, 0.6);
}

.ui.positive.button,
.ui.posotive.buttons .button {
  font-family: "Open Sans", sans-serif !important;
  background-color: #dbdfe0 !important;
  color: #7d7d7d;
  font-weight: bold;
}

.ui.teal.sub.header,
.ui.blue.sub.header {
  color: #c00000 !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-transform: none;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.auxs {
  color: blue !important;
}

.react-datepicker {
  border-radius: 1 !important;
  box-shadow: none !important;
  height: auto !important;
}

.react-datepicker::before,
.react-datepicker::after {
  height: auto !important;
}

.react-datepicker__header {
  background: transparent !important;
  border-bottom: 0 !important;
}

.react-datepicker__header > div > div {
  margin: 1em auto;
  text-transform: capitalize;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px;
}

.react-datepicker__header.react-datepicker__header--custom {
  margin: 0 !important;
  padding: 0 !important;
}

.react-datepicker__header > div:nth-child(1) {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.react-datepicker__header > div:nth-child(2) {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.react-datepicker__header > div > button {
  background-color: transparent !important;
  color: #c00000;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 800;
  font-size: 2em !important;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  font-family: "Open sans", sans-serif;
  font-weight: 600;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #c00000 !important;
  border-radius: 1 !important;
  color: white !important;
}

.react-datepicker__day--selected {
  background: #c00000 !important;
}

.react-datepicker.react-datepicker--time-only {
  max-height: auto !important;
  min-height: auto !important;
  height: auto !important;
}

.react-datepicker__time-list-item.text-success {
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.react-datepicker__time-list-item--selected,
.react-datepicker__time-list-item.text-success:hover {
  background-color: #c00000 !important;
  color: white !important;
  border-radius: 5px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  border-radius: 5px !important;
}
