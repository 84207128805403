* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center !important;
  margin: 0 auto !important;
  padding: 0 !important;
  width: 450px !important;
  min-height: calc(91vh - 61px);
}

.cancel {
  min-height: calc(91vh - 61px);
}

@media screen and (max-width: 1440px) {
  .App {
    text-align: center !important;
    margin: 0 auto !important;
    padding: 0 !important;
    width: 450px !important;
    min-height: calc(82vh - 39px);
  }
  .cancel {
    min-height: calc(82vh - 39px);
  }

  .ui.clearing.segment {
    border-radius: 0 !important;
    width: 450px !important;
  }
}

@media (max-width: 575.98px) {
  .App {
    text-align: center !important;
    margin: 0 auto !important;
    padding: 0 !important;
    width: 320px !important;
    min-height: calc(81vh - 28px);
  }
  .cancel {
    min-height: calc(81vh - 28px);
  }

  .ui.basic.red.button,
  .ui.basic.red.buttons .button {
    box-shadow: 0 0 0 1px #db2828 inset !important;
    color: #db2828 !important;
    height: 84px !important;
  }

  .ui.clearing.segment {
    border-radius: 0 !important;
    width: 316px !important;
  }

  .small-div {
    min-width: 0 !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .App {
    text-align: center !important;
    margin: 0 auto !important;
    padding: 0 !important;
    width: 354px !important;
    min-height: calc(82vh - 28px);
  }
  .cancel {
    min-height: calc(82vh - 28px);
  }

  .ui.basic.red.button,
  .ui.basic.red.buttons .button {
    box-shadow: 0 0 0 1px #db2828 inset !important;
    color: #db2828 !important;
    height: 84px;
  }

  .ui.clearing.segment {
    border-radius: 0 !important;
    width: 316px !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .App {
    text-align: center !important;
    margin: 0 auto !important;
    padding: 0 !important;
    width: 450px !important;
    min-height: calc(89vh - 34px);
  }
  .cancel {
    min-height: calc(82vh - 28px);
  }
}

.ui.modal > .actions {
  background: #f9fafb;
  padding: 1rem 1rem;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  text-align: center !important;
}

.react-datepicker {
  height: 300px !important;
  width: 290px !important;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15) !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.ui.inverted.menu {
  background-color: #414141 !important;
}

.react-datepicker__header {
  margin-bottom: 20px !important;
  width: 288px !important;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap !important;
  font-size: 1.2rem !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0 !important;
  color: #000 !important;
  font-weight: bold !important;
  font-size: 1.2rem !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: inline-block !important;
  width: 2rem !important;
  line-height: 2rem !important;
  text-align: center !important;
  margin: 0.25rem !important;
}

.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default !important;
  color: #ccc !important;
}

.react-datepicker.react-datepicker--time-only {
  max-height: auto;
  min-height: 600px;
  border: none !important;
  box-shadow: none !important;
}

.react-datepicker__time-container {
  width: 288px !important;
}

.react-datepicker-time__header {
  color: #fff !important;
  display: none;
}

.react-datepicker__header--time {
  text-align: center !important;
  background-color: #ffff !important;
  border-bottom: 0px solid #aeaeae !important;
  border-top-left-radius: 0.3rem !important;
  padding: 0 !important;
  margin: 0 !important;
  position: relative !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 288px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  max-height: auto;
  min-height: 270px;
  padding: 0;
  overflow-y: hidden !important;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  display: hidden;
  box-sizing: content-box;
  font-size: 1.2rem;
}

ul {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: repeat(14, 45px);
}

.react-datepicker__time-list-item--disabled {
  color: red !important;
  pointer-events: none;
  text-decoration: line-through;
  display: none;
}

.button {
  background-color: #0275d8;
  color: white;
  padding: 10px 20px;
  border: 1px solid darkgray;
  transition: all 0.3s;
  font-size: 15px;
  border-radius: 0.28571429rem;
}

.button:hover {
  border: 0.2px solid white;
  opacity: 0.6;
  cursor: pointer;
}

.text-success {
  color: green;
  font-weight: bold;
}

.text-error {
  display: none;
}

.ui.inverted.stackable.footer.menu,
.footer {
  height: 43px;
}

.ui.inverted.menu {
  border: 0 solid transparent;
  background: #333 !important;
  box-shadow: none;
}
